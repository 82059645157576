import React from 'react'
import Layout from "../components/layout";
import logoAsc from "../images/logo-asc.jpg";


export default function impressum() {
    return (
        <Layout>
            <div className="w-full max-w-3xl p-4 mx-auto text-black text-center py-32">
                <p className="text-4xl mx-auto text-left mb-4 font-bold text-gray-800">Impressum - Legal Notice</p>
                <div className="h-1 mx-auto gradient opacity-25 rounded"></div>
                <p className="text-1xl mx-auto text-left mt-4 mb-4 text-gray-600">
                    The following information (Impressum) is required under Austrian law: »§14 Unternehmensgesetzbuch,
                    §63 Gewerbeordnung, §5 E-Commerce Gesetz, §25 Mediengesetz«
                </p>
                <br/>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-800">
                    <img className="logo-alt" src={logoAsc} />
                </p>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    Ing. Mag. Andreas Schatz 
                </p>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    25. Straße Nr. 1 
                    <br/>
                    3331 Kematen an der Ybbs
                    <br/>
                    Austria
                </p>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    E-Mail: office@gmail2ecm.com
                </p>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    Line of business: IT-Consulting
                </p>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    VAT ID No.: ATU60128007
                </p>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    Complaints department: office@gmail2ecm.com
                </p>
                <br/>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    <b>Online Dispute Resolution website of the EU Commission</b><br/>
                    In order for consumers and traders to resolve a dispute out-of-court, the European Commission
                    developed the Online Dispute Resolution Website: <a href="https://ec.europa.eu/consumers/odr" target="_blank" class="link-blue">ec.europa.eu/consumers/odr</a>
                </p>
                <br/>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    <b>Legal disclaimer</b><br/>
                    The contents of these pages were prepared with utmost care. Nonetheless, we cannot assume liability for the timeless accuracy
                    and completeness of the information. Therefore we exclude all liability for the topicality, correctness, completeness or quality of the information provided.
                    We expressly reserve the right to change, supplement and delete parts of the website or to suspend or temporarily withdraw the complete publication without
                    separate announcement.
                    <br/>
                </p>
            </div>
        </Layout>
    )
}
